import { gql } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { USER_PRIVATE_FIELDS_FRAGMENT } from './user/userPrivateFieldsFragment';
import { USER_NOTIFICATION_SETTINGS_FRAGMENT } from './user/userNotificationSettingsFragment';
import { USER_PUBLISHING_SETTINGS_FRAGMENT } from './user/userPublishingSettingsFragment';
import { USER_PUBLIC_FIELDS_FRAGMENT } from './user/userPublicDataFragment';
import { USER_ACTIVE_MEMBERSHIP_FRAGMENT } from './user/userActiveMembershipsFragment';
import { BASE_IMAGE_FRAGMENT } from './image/baseImageFragment';
import { AUTHENTICATED_ITEM_FIELDS_FRAGMENT } from './authenticatedItemFieldsFragment';
import { ROLE_CARD_FRAGMENT } from './role/roleCardFragment';

export const fragments = createFragmentRegistry(gql`
  # CloudinaryImage_File
  ${BASE_IMAGE_FRAGMENT}

  # User
  ${USER_PUBLIC_FIELDS_FRAGMENT}
  ${USER_ACTIVE_MEMBERSHIP_FRAGMENT}
  ${USER_PRIVATE_FIELDS_FRAGMENT}
  ${USER_NOTIFICATION_SETTINGS_FRAGMENT}
  ${USER_PUBLISHING_SETTINGS_FRAGMENT}
  ${AUTHENTICATED_ITEM_FIELDS_FRAGMENT}

  # Role
  ${ROLE_CARD_FRAGMENT}
`);
