import { useEffect, useState } from 'react';

export enum ScrollDirection {
  up = 'up',
  down = 'down',
}

const useDetectScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(ScrollDirection.up);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;

      if (scrollY === 0) {
        return setScrollDirection(ScrollDirection.up);
      }

      const direction = scrollY < lastScrollY ? ScrollDirection.up : ScrollDirection.down;
      setScrollDirection(direction);
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener('scroll', updateScrollDirection);
    return () => {
      window.removeEventListener('scroll', updateScrollDirection);
    };
  }, []);

  return scrollDirection;
};

export default useDetectScrollDirection;
