import { gql } from '@apollo/client';

export const ROLE_CARD_FRAGMENT = gql`
  fragment RoleCard on Role {
    id
    name
    flairColor
    description
    duration
    participateInEarnings
    inviteOnly
    roleImage {
      ...BaseImageFields
    }
    prices {
      id
      price
      type
      currencyLabel
    }
  }
`;
