import isEmpty from 'lodash/isEmpty';

type TReturn = { results: object[]; resultsCount: number };

export const skipLimitPaginationForCustomResults = (keyArgs?: string[]) => ({
  keyArgs: keyArgs || (false as false),
  merge(existing: TReturn, incoming: TReturn, { args }: any) {
    const merged = !isEmpty(existing?.results)
      ? {
          results: existing?.results?.slice?.(0),
          resultsCount: incoming?.resultsCount,
        }
      : {
          results: [],
          resultsCount: incoming?.resultsCount,
        };

    if (!args) {
      throw new Error(`No args provided to paginated field, keyArgs: ${keyArgs?.join(', ')}`);
    }

    const { skip = 0 } = args;

    if (!skip) {
      return incoming;
    }

    for (let i = 0; i < incoming.results.length; i += 1) {
      merged.results[skip + i] = incoming.results[i];
    }

    return merged;
  },
});
